import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const types = {
    SAVE_ACCESS_TOKEN: 'SAVE_ACCESS_TOKEN',
    AUTH_SUCCESS: 'AUTH_SUCCESS',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
}

const initAuthState = {
    user: undefined,
    accessToken: undefined
}

export const actions = {
    saveAccessToken: (accessToken: string) => ({type: types.SAVE_ACCESS_TOKEN, payload: accessToken}),
    authSuccess: (result: any) => ({type: types.AUTH_SUCCESS, payload: result}),
    logoutSuccess: () => ({type: types.LOGOUT_SUCCESS})
}

const AuthReducer = persistReducer(
    {storage, key: 'auth-sent', whitelist: ['user','accessToken']}, (state: any = initAuthState, action: any) => {
        switch (action.type) {
            case types.SAVE_ACCESS_TOKEN : {
                return {
                    ...state,
                    accessToken: action.payload
                }
            }
            
            case types.AUTH_SUCCESS : {
                return {
                    ...state,
                    accessToken: action.payload.token,
                    user: action.payload.user
                }
            }

            case types.LOGOUT_SUCCESS : {
                //console.log(initAuthState)
                return initAuthState
            }

            default :
                return state
        }
    })

export default AuthReducer
