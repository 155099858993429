/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {KTSVG, checkCurrentApp, toAbsoluteUrl} from '../../../helpers'
import {actions} from '../../../../setup/redux/AppRedux'
// import {CheckAccess} from '../../../../app/Utilities';

const QuickLinks: FC = () => {
  const dispatch = useDispatch()

  const onClick = (e:any) => {
    const dataCurrentApp = e.target.getAttribute("data-current-app");
    dispatch(actions.saveCurrentApp(dataCurrentApp))
  }

  return (
    <>
      <div className='menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px' data-kt-menu='true'>
        <div className='d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10'
          style={{backgroundImage: `url('${toAbsoluteUrl('/media/defaults/pattern-4.jpg')}')`}}>
          <h3 className='fw-bold text-white text-uppercase mb-3'>
            Modules Smart Sentino
          </h3>
          <span className='badge bg-orange py-2 px-3'>
            Version 2.0
          </span>
        </div>

        <div className='row g-0'>
          <div className='col'>
            <Link onClick={(e) => {onClick(e)}}
              className={clsx('d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom', {
                active: checkCurrentApp('GP'),
              })}
              to={'/instrument/dashboard'}
            >
              <KTSVG path='/media/icons/duotone/Tools/Tools.svg' className='svg-icon-3x svg-icon-primary mb-2'/>
              <span data-current-app={'GP'} className='fs-5 fw-bold text-gray-800 text-center text-uppercase mb-0'>
                Gestion parc instruments
              </span>
            </Link>
          </div>

          <div className='col'>
            <Link onClick={(e) => {onClick(e)}}
              className={clsx('d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom', {
                active: checkCurrentApp('SE'),
              })}
              to={'/execution/dashboard'}
            >
              <KTSVG path='/media/icons/duotone/Interface/Monitor.svg' className='svg-icon-3x svg-icon-primary mb-2' />
              <span data-current-app={'SE'} className='fs-5 fw-bold text-gray-800 text-center text-uppercase mb-0'>
                Gestion suivi de l'éxécution
              </span>
            </Link>
          </div>

          <div className='col'>
            <Link onClick={(e) => {onClick(e)}}
              className={clsx('d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom', {
                active: checkCurrentApp('GC'),
              })}
              to={'/business/dashboard'}
            >
              <KTSVG path='/media/icons/duotone/Shopping/Money.svg' className='svg-icon-3x svg-icon-primary mb-2' />
              <span data-current-app={'GC'} className='fs-5 fw-bold text-gray-800 text-center text-uppercase mb-0'>
                Gestion commerciale
              </span>
            </Link>
          </div>

          <div className='col'>
            <Link onClick={(e) => {onClick(e)}}
              className={clsx('d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom', {
                active: checkCurrentApp('RE'),
              })}
              to={'/reporting/dashboard'}
            >
              <KTSVG path='/media/icons/duotone/Interface/Doughnut.svg' className='svg-icon-3x svg-icon-primary mb-2' />
              <span data-current-app={'RE'} className='fs-5 fw-bold text-gray-800 text-center text-uppercase mb-0'>
                Reporting
              </span>
            </Link>
          </div>
        </div>
        
        {/* <div className='py-2 text-center border-top'>
          <Link to='/crafted/pages/profile' className='btn btn-color-gray-600 btn-active-color-primary'>
            View All 
            <KTSVG path='/media/icons/duotone/Navigation/Right-2.svg' className='svg-icon-5' />
          </Link>
        </div> */}
      </div>
    </>
  )
}

export {QuickLinks}
