// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/helpers";
import uuid from 'react-uuid'

export function ActionsColumnFormatter(
    cellContent,
    row,
    rowIndex,
    {openEditTableDialog, openDeleteTableDialog}
) {


    return null

    return (
        <>
            <a
                className="btn btn-icon btn-light-primary btn-hover-primary btn-sm mx-3"
                onClick={() => openEditTableDialog(row.id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                      src={toAbsoluteUrl("/media/icons/duotone/Communication/Write.svg")}
                  />
              </span>
            </a>

            <a
                className="btn btn-icon btn-light-danger btn-hover-danger btn-sm"
                onClick={() => openDeleteTableDialog(row.id)}
            >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG src={toAbsoluteUrl("/media/icons/duotone/General/Trash.svg")}/>
                </span>
            </a>
        </>
    );

}

export function ActionsColumnFormatterParams(
    cellContent,
    row,
    rowIndex,
    paramActions
) {
    return (
        <>
            {
                paramActions
                    ?
                    paramActions.map((paramAction, paramActionIndex) => {

                        if (paramAction?.hasAccess) {

                            const style_in = {
                                cursor: "pointer",
                            }

                            const style = {...paramAction?.style || {}, ...style_in}

                            if (paramAction?.fileField) {
                                if(!row[paramAction?.fileField])
                                    return null
                                return (
                                    <a
                                        style={style}
                                        key={uuid()}
                                        title={paramAction.title}
                                        className={paramAction.className}
                                        href={row[paramAction?.fileField] || void (0)}
                                        target="_blank"
                                        download
                                    >
                                <span className={"svg-icon svg-icon-md " + paramAction.svg.className}>
                                    <SVG
                                        src={toAbsoluteUrl(paramAction.svg.path)}
                                    />
                                </span>
                                    </a>
                                )
                            } else {
                                if(paramAction?.fieldShowIfNotNull)
                                    if(!row[paramAction?.fieldShowIfNotNull] || row[paramAction?.fieldShowIfNotNull] == 0)
                                        return null

                                return (
                                    <a
                                        style={style}
                                        key={uuid()}
                                        title={paramAction.title}
                                        className={paramAction.className}
                                        onClick={() => paramAction?.field ? paramAction.onClick(row[paramAction?.field]) : paramAction.onClick(row.id)}
                                        href={void (0)}
                                    >
                                        <span className={"svg-icon svg-icon-md " + paramAction.svg.className}>
                                            <SVG
                                                src={toAbsoluteUrl(paramAction.svg.path)}
                                            />
                                        </span>
                                    </a>
                                )
                            }
                        }
                    })

                    : ""
            }
        </>
    );
}

export function ActionsColumnFormatterDropDown(
    cellContent,
    row,
    rowIndex,
    paramActions
) {
    return (
        <>
            <div className="dropdown">
                <a className="btn btn-sm btn-light-primary dropdown-toggle " style={{padding: '5px'}} href={void (0)}
                   role="button" id="dropdownMenuLink"
                   data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fas fa-cogs"></i>
                </a>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    {
                        paramActions
                        ?
                        paramActions.map((paramAction, paramActionIndex) => {
                            /* if(row['archived_at'])
                                return null*/
                            
                            if (paramAction?.hasAccess) {
                                const style_in = {
                                    cursor: "pointer",
                                    padding: "0.75rem 1.25rem"
                                }
                                const style = {...paramAction?.style || {}, ...style_in}

                                if (paramAction?.fileField) {
                                    if(!row[paramAction?.fileField])
                                        return null

                                    return (
                                        <a
                                            key={uuid()}
                                            style={style}
                                            className={"dropdown-item menu-link  px-3 "}
                                            title={paramAction.title}
                                            href={row[paramAction?.fileField] || void (0)}
                                            download
                                        >
                                            <span className={"svg-icon svg-icon-md " + paramAction.svg.className}>
                                                <SVG
                                                    src={toAbsoluteUrl(paramAction.svg.path)}
                                                />
                                            </span>
                                            {" " + paramAction.title}
                                        </a>
                                    )
                                } else {
                                    if(paramAction?.fieldShowIfNotNull)
                                        if(!row[paramAction?.fieldShowIfNotNull] || row[paramAction?.fieldShowIfNotNull] == 0)
                                            return null
                                    
                                    return (
                                        <a
                                            key={uuid()}
                                            style={style}
                                            className={"dropdown-item menu-link  px-3 "}
                                            title={paramAction.title}
                                            href={void (0)}
                                            onClick={() => paramAction?.field ? paramAction.onClick(row[paramAction?.field]) : paramAction.onClick(row.id)}
                                        >
                                            <span className={"svg-icon svg-icon-md " + paramAction.svg.className}>
                                                <SVG
                                                    src={toAbsoluteUrl(paramAction.svg.path)}
                                                />
                                            </span>
                                            {" " + paramAction.title}
                                        </a>
                                    )
                                }
                            }
                        })
                        : ""
                    }
                </div>
            </div>
        </>
    );
}
