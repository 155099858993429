/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Modal} from 'bootstrap';

import {priorities_type} from "../../../../Types";
import {CheckAccess, currentTime, responseApi} from '../../../../Utilities';
import DataTable from '../../../../../services/Table/DataTable'
import * as columnFormatters from "../../../../../services/Table/column-formatters";
import {notify} from "../../../../../services/Toastify/Toastify";
import {SweetAlert, SweetAlertInfo} from '../../../../../services/Sweetalert2/SweetAlert'
import {ExportToExcelTable} from "../../../../../services/Excel/ExportToExcel";

import PlanningModel from '../../../../../models/se/PlanningModel'
import {PlanningModal} from './PlanningModal'

type Props = {
    className: string
}

const PlanningsView: React.FC<Props> = ({className}) => {
    const [tableReload, setTableReload] = useState(currentTime)
    const [planningId, setPlanningId] = useState(null)

    const modalActions = {
        open: (id: any) => {
            setPlanningId(prevState => id)
            // @ts-ignore
            let myModal = new Modal(document.getElementById('planning_modal'), {});
            myModal.show();
        },
        close: () => {
            setPlanningId(prevState => null)
        }
    }

    const ActionEvents = {
        openEditTableDialog: async (id: any) => {
            SweetAlert('warning', '', 'Voulez-vous vraiment modifier cette planification ?', () => modalActions.open(id))
        },
        openQuotaTableDialog: async (id: any) => {
            let response = responseApi;
            const getQuota = async () => {
                const dataModel = new PlanningModel()
                const {data} = await dataModel.getQuota(id)
                response = data;
                if (response.type !== null && response.result !== null) {
                    notify(response.type, response.message)
                    if (response.type === "success") {
                        SweetAlertInfo(response?.result || "")
                    }
                }
            }
            getQuota();
        }
    }

    const columns = [
        {
            dataField: "priority_planning",
            text: "Priorité",
            sort: true,
            search: {
                type: 'select',
                data: priorities_type
            },
            render: (row: any, rowIndex: any) => {

                switch (row.priority_planning) {
                    case priorities_type[0].value :
                        return <>
                            <span className="badge badge-danger">{priorities_type[0].label}</span>
                        </>
                        break;
                    case priorities_type[1].value :
                        return <>
                            <span className="badge badge-success">{priorities_type[1].label}</span>
                        </>
                        break;
                    case priorities_type[2].value :
                        return <>
                            <span className="badge badge-warning">{priorities_type[2].label}</span>
                        </>
                        break;
                }
            }
        },
        // {
        //     dataField: "se_reception.num_rec",
        //     text: "N° REC",
        //     sort: true,
        //     search: {},
        //     render: (row: any, rowIndex: any) => {
        //         return (
        //             <>{row?.se_reception_num_rec}</>
        //         )
        //     }
        // },
        {
            dataField: "gc_commande.num_bon",
            text: "N° BC",
            sort: true,
            search: {},
            render: (row: any, rowIndex: any) => {
                return (
                    <>{row?.gc_commande_num_bon}</>
                )
            }
        },
        {
            dataField: "gc_client.nom_client",
            text: "Client",
            sort: true,
            search: {},
            render: (row: any, rowIndex: any) => {
                return (
                    <>{row?.gc_client_nom_client}</>
                )
            }
        },
        {
            dataField: "gc_client.address_client",
            text: "Adresse",
            sort: true,
            search: {},
            render: (row: any, rowIndex: any) => {
                return (
                    <>{row?.gc_client_address_client}</>
                )
            }
        },
        {
            dataField: "date_debut_planning",
            text: "Date de début",
            sort: true,
            search: {},
        },
        {
            dataField: "date_fin_planning",
            text: "Date de fin",
            sort: true,
            search: {},
        },

        {
            dataField: "quota_planning",
            text: "Quota",
        },

        {
            dataField: "action",
            text: "Actions",
            classes: "text-right pr-0",
            style: {},
            formatter: columnFormatters.ActionsColumnFormatterParams,
            formatExtraData: [
                {
                    title: "Modifier la planification",
                    className: "btn btn-icon btn-light-primary btn-hover-primary btn-sm me-1",
                    svg: {
                        path: "/media/icons/duotone/Communication/Write.svg",
                        className: "svg-icon-primary"
                    },
                    hasAccess: CheckAccess("department-edit"),
                    onClick: ActionEvents.openEditTableDialog
                },
            ],
            headerClasses: "text-right pr-3 w-5",
            headerStyle: {
                width: "8%"
            },
        },
    ];

    const filename = ""+currentTime();
    const exportToExcel = () => {
        (async () => {
            await ExportToExcelTable("suivi_planification_"+filename, new PlanningModel(), "", columns, {per_page:"all"});
        })()
    }

    return (
        <>
            <div className={`card ${className}`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder fs-2 mb-1'>
                            Liste de suivi des planifications
                        </span>
                    </h3>
                    <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover'>
                        {
                            <a href={void(0)} onClick={() => exportToExcel()} className="btn btn-sm btn-light-success ms-2">
                                <i className="fas fa-file-excel"></i> Exporter
                            </a>
                        }
                    </div>
                </div>
                {/* end::Header */}

                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        <DataTable
                            tableReload={tableReload}
                            dataModel={new PlanningModel()}
                            columns={columns}
                            defaultParams={{
                                status_order: 'opened'
                            }}
                        />
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>

            <PlanningModal planningId={planningId} modalActions={modalActions} setTableReload={setTableReload} />
        </>
    )
}

export {PlanningsView}
