import React, {useEffect, useState} from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {MasterInit} from './MasterInit'
import {PageDataProvider} from './core'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import PermissionModel from '../../models/gp/PermissionModel'
import {actions as appActions} from '../../setup/redux/AppRedux'
import {RootState} from '../../setup/redux/Store'
import SettingModel from '../../models/gp/SettingModel'
import {CurrentUser} from '../../app/Utilities'

const MasterLayout: React.FC = ({children}) => {
    const cdi = useSelector((state: RootState) => state.appReducer.cdi, shallowEqual)
    const dispatch = useDispatch()


    const getCurrentUserAccess = async () => {
        const {data} = await new PermissionModel().getCurrentUserAccess()
        if (data.type === 'success') {
            await dispatch(appActions.saveCurrentUserAccess(data.result))
            //console.log(data.result);
        } else
            await dispatch(appActions.saveCurrentUserAccess([]))
    }

    const getSetting = async () => {
        let values = {
            suivi_qualif: 0,
            suivi_verif: 0,
            service_provider: 0,
        }
        let dataModel = new SettingModel()
        const {data} = await dataModel.getSetting()
        if (data.type === 'success') {
            values.suivi_qualif = data.result.suivi_qualif
            values.suivi_verif = data.result.suivi_verif
            values.service_provider = data.result.service_provider
            //console.log(values)
            await dispatch(appActions.saveSettingSubModule(values))
        } else
            await dispatch(appActions.saveSettingSubModule(values))
    }


    useEffect(() => {
        if (cdi) {
            (async () => {
                await getCurrentUserAccess()
                await getSetting()
            })()
        }

        //console.log(cdi)
    }, [cdi])

    useEffect(() => {
        (async () => {
            await CurrentUser(dispatch)
        })()
    }, [])


    if (!cdi)
        return null



    return (
        <>

                <PageDataProvider>
                    <div className='page d-flex flex-row flex-column-fluid'>
                        <AsideDefault/>
                        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                            <HeaderWrapper/>

                            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                                <Toolbar/>
                                <div className='post d-flex flex-column-fluid' id='kt_post'>
                                    <Content>{children}</Content>
                                </div>
                            </div>
                            <Footer/>
                        </div>
                    </div>

                    <MasterInit/>
                    <ScrollTop/>
                </PageDataProvider>

        </>
    )
}

export {MasterLayout}
