export const reason_out_mat_type = [
    {
        label: "Sortie (pour mission)",
        value: "sortie_mission",
    },
    {
        label: "Sortie (pour raccordement)",
        value: "sortie_raccordement",
    }
]

export const priorities_type = [
    {
        label: "La plus haute",
        value: 'haute',
    },
    {
        label: "Normale",
        value: 'normal',
    },
    {
        label: "La plus basse",
        value: 'basse',
    }
]


export const realizations_places_type = [
    {
        label: "Au laboratoire",
        value: 'labo',
    },
    {
        label: "Sur site",
        value: 'site',
    },
    {
        label: "Au laboratoire / Sur site",
        value: 'labo-site',
    }
]


export const natures_prestations_type = [
    {
        label: "Etalonnage",
        value: 'etalonnage',
        is_prestation: 1,
    },
    {
        label: "Vérification",
        value: 'verification',
        is_prestation: 1,
    },
    {
        label: "Etalonnage/Vérification",
        value: 'etalonnage-verification',
        is_prestation: 1,
    },
    {
        label: "Qualification",
        value: 'qualification',
        is_prestation: 1,
    },
    {
        label: "Caractérisation",
        value: 'caracterisation',
        is_prestation: 1,
    },
    {
        label: "Formation",
        value: 'formation',
        is_prestation: 0,
    },
    {
        label: "Logiciel",
        value: 'logiciel',
        is_prestation: 0,
    },
    {
        label: "Location",
        value: 'location',
        is_prestation: 0,
    }
]

export const registres_type = [
    {
        label: "Certificat",
        value: 'certificat',
    },
    {
        label: "Constat",
        value: 'constat',
    },
]

export const accreditations_type = [
    {
        label: "Avec accréditation",
        value: 'A',
    },
    {
        label: "Sans accréditation",
        value: 'SA',
    },
]

export const memosPrintCode = 'EN05.FAR'
export const quoteOfferMessage = "Nous avons le plaisir de vous faire part de notre offre de prix relative à vos équipements ci-dessous :"
// true or false
export const quoteCommentActived = true

export const countryICEText = 'ICE'
export const countryPatentText = 'Patente'
export const countryRCText = 'RC'
export const countryIFText = 'IF'
export const countryCNSSText = 'CNSS'

export const tvaLowArticle = "Facture exonérée de la TVA conformément au disposition de l'article 92-I-1° et 92-I-36° du CGI"
// true or false
export const activeTTCInfos = true
export const currentCurrencyName = 'MAD'
export const currentCurrencyCode = 'MAD'
export const initialTvaValue = 0
export const currentTvaValue = 20
export const tva_type = [
    {
        label: "0 %",
        value: '0',
    },
    {
        label: currentTvaValue+" %",
        value: currentTvaValue,
    },
]

export const payment_mode_type = [
    {
        label: "Effet",
        value: 'effet',
    },
    {
        label: "Chèque",
        value: 'cheque',
    },
    {
        label: "Virement",
        value: 'virement',
    },
    {
        label: "Espèce",
        value: 'espece',
    },
]

export const quote_is_send_badge_type = [
    {
        label: "Oui",
        value: 1,
    },
    {
        label: "Non",
        value: 0,
    },
]

export const yes_or_no_badge_type = [
    {
        label: "Oui",
        value: 'oui',
    },
    {
        label: "Non",
        value: 'non',
    },
]

export const status_order_badge_type = [
    {
        label: "Ouverte",
        value: 'opened',
    },
    {
        label: "Clôturée",
        value: 'closed',
    },
]

export const status_livraison_badge_type = [
    {
        label: "AUCUNE",
        value: 'aucune',
    },
    {
        label: "PARTIELLE",
        value: 'partielle',
    },
    {
        label: "COMPLETE",
        value: 'complete',
    },
]

export const status_invoice_badge_type = [
    {
        label: "PAYE",
        value: 'paye',
    },
    {
        label: "PARTIEL",
        value: 'partiel',
    },
    {
        label: "NON ECHUE",
        value: 'non_echue',
    },
    {
        label: "NON PAYE",
        value: 'non_paye',
    },
]

export const status_invoice_type = [
    {
        label: "Totalement payé",
        value: 'paye',
    },
    {
        label: "Partiellement payé",
        value: 'partiel',
    },
]

export const months_type = [
    {
        label: "Janvier",
        abbr: "Jan.",
        value: 1,
    },
    {
        label: "Février",
        abbr: "Fév.",
        value: 2,
    },
    {
        label: "Mars",
        abbr: "Mars",
        value: 3,
    },
    {
        label: "Avril",
        abbr: "Avr.",
        value: 4,
    },
    {
        label: "Mai",
        abbr: "Mai",
        value: 5,
    },

    {
        label: "Juin",
        abbr: "Juin",
        value: 6,
    },
    {
        label: "Juillet",
        abbr: "Juill.",
        value: 7,
    },
    {
        label: "Aôut",
        abbr: "Aôut",
        value: 8,
    },
    {
        label: "Septembre",
        abbr: "Sept.",
        value: 9,
    },
    {
        label: "Octobre",
        abbr: "Oct.",
        value: 10,
    },
    {
        label: "Novembre",
        abbr: "Nov.",
        value: 11,
    },
    {
        label: "Décembre",
        abbr: "Déc.",
        value: 12,
    },
]

export const initialQuoteLine = {
    prestation_id: '',
    prestation_json: null,
    designation: '',
    nature: '',
    standard_program: '',
    client_program: '',
    connection_status: '',
    realization_place: '',
    decision_rule: '',
    client_decision_rule: '',
    indication: '',
    note_client: '',
    doc_certificat: '',
    doc_constat: '',
    quantity: 1,
    unit_price_ht: 0,
    discount: 0,
    price_ht: 0,
}
export const initialOrderLine = initialQuoteLine;
export const initialInvoiceLine = initialOrderLine;

export const initialMaterialCriticalityLine = {
    criticality_question_id: '',
    answer: '',
}
