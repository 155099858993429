/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import * as Yup from 'yup';
import clsx from 'clsx'
import {useFormik} from 'formik'
import {DefaultEditor} from "react-simple-wysiwyg";

import {currentTime, responseApi} from '../../../../Utilities'
import {notify} from "../../../../../services/Toastify/Toastify";
import {useSafeState} from "../../../../../services/Hooks/useSafeState";

import PlanningModel from '../../../../../models/se/PlanningModel';

type Props = {
    planningId: any
    modalActions: {
        open: (id: any) => void;
        close: () => void;
    }
    setTableReload: any,
}

const formInitial = {
    comment_todo_planning: '',
}

const ToDoModal: React.FC<Props> = ({planningId, modalActions, setTableReload}) => {
    const [formState, setFormState] = useSafeState(formInitial)
    const [loading, setLoading] = useState(false)

    // @ts-ignore
    const isAddMode = !planningId;

    const findPlanning = async (planningId: bigint) => {
        let dataModel = new PlanningModel()
        const {data} = await dataModel.find(planningId)
        if (data.type === "success") {
            setFormState({
                ...formState,
                num_bon_planning: data.result.num_bon || "",
                comment_todo_planning: data.result.comment_todo_planning || "",
            })
        } else
            setFormState(formInitial)
    }

    const formSchema = Yup.object().shape({
        comment_todo_planning: Yup.string().nullable(),
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formState,
        validationSchema: formSchema,
        onSubmit: (values, {setSubmitting, resetForm}) => {
            setLoading(true)
            setTimeout(async () => {
                let response = responseApi;
                const planning = new PlanningModel()
                const {data} = await planning.saveCommentTodoPlanning(planningId, values)
                response = data;
                if (response.type !== null && response.message !== null) {
                    notify(response.type, response.message)
                    if (response.type === "success") {
                        if (!planningId)
                            resetForm();
                        setTableReload(currentTime)
                    }
                } else {
                    notify("error", "Opération non effectuée | catch error")
                }
                setLoading(false)
                setSubmitting(false)
            }, 1000)
        },
    })

    const initForm = () => {
        // setLoading(true)
        if (planningId) {
            findPlanning(planningId).then((response) => {
                // setLoading(false)
            })
        } else
            setFormState(formInitial)
    }

    useEffect(() => {
        initForm()
    }, [planningId])

    const onChangeEditor = (event: any) => {
        const {value} = event.target
        const name = event.currentTarget.attributes.name.value
        setFormState({
            ...formState,
            [name]: value
        })
    }

    return (
        <>
            <div className='modal fade' id='todo_comment_modal' data-bs-backdrop="static" aria-hidden='true'>
                <div className='modal-dialog mw-900px'>
                    <div className='modal-content'>
                        <div className='modal-header pb-0 border-0 justify-content-end'>
                            <div className='btn btn-sm btn-icon btn-active-color-orange' data-bs-dismiss='modal' onClick={() => modalActions.close()}>
                                <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1'/>
                            </div>
                        </div>

                        <div className='modal-body scroll-y mx-2 mx-xl-8 pt-0 pb-15'>
                            <div className='text-center mb-5'>
                                <h1 className='mb-3'>
                                    Commentaire de la tâche [{formState?.num_bon_planning}]
                                </h1>
                            </div>

                            <form onSubmit={formik.handleSubmit} className='form w-100' noValidate>
                                {/* begin::Form group */}
                                <div className='row mb-4'>
                                    <div className='col-lg-12'>
                                        <DefaultEditor
                                            {...formik.getFieldProps('comment_todo_planning')}
                                            styles={
                                                {
                                                    editor : {
                                                        minHeight: "250px"
                                                    }
                                                }
                                            }
                                            value={formState?.comment_todo_planning}
                                            onChange={onChangeEditor}
                                        />
                                        {formik.touched.comment_todo_planning && formik.errors.comment_todo_planning && (
                                            <div className='fv-plugins-message-container'>
                                                <span role='alert'>{formik.errors.comment_todo_planning}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* end::Form group */}

                                {/* begin::Action */}
                                <div className='text-center mt-8'>
                                    <button type='submit' id='kt_sign_in_submit' disabled={formik.isSubmitting || !formik.isValid} className='btn btn-lg btn-orange w-100 mb-5'>
                                        {!loading && <span className='indicator-label text-uppercase'>Enregistrer</span>}
                                        {loading && (
                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                                {/* end::Action */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export {ToDoModal}
