/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'

import {AsideMenuMainGP} from './AsideMenuMainGP'
import {AsideMenuMainSE} from './AsideMenuMainSE'
import {AsideMenuMainGC} from './AsideMenuMainGC'
import {AsideMenuMainRE} from './AsideMenuMainRE'

const RenderAside = ({currentApp}) => {
 // console.log(currentApp)

  switch (currentApp) {
    case "GP":
      return (
        <AsideMenuMainGP />
      )
      break;
    
    case "SE":
      return (
        <AsideMenuMainSE />
      )
      break;

    case "GC":
      return (
        <AsideMenuMainGC />
      )
      break;

    case "RE":
      return (
        <AsideMenuMainRE />
      )
      break;
  
    default:
      return (
        <AsideMenuMainGP />
      )
      break;
  }
}

export const AsideMenuMain  = ({currentApp}) => {
  const intl = useIntl()
  return (
    <>
      <RenderAside currentApp={currentApp} />
    </>
  )
}
