/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'

import {CheckAccess, currentTime} from '../../../../../Utilities';
import DataTable from '../../../../../../services/Table/DataTable'
import {ExportToExcelTable} from "../../../../../../services/Excel/ExportToExcel";

import DashBoardModel from '../../../../../../models/se/DashBoardModel'

type Props = {
    className: string
}

const UnplannedOrdersView: React.FC<Props> = ({className}) => {
    const [tableReload, setTableReload] = useState(currentTime)

    const columns = [
        {
            dataField: "date_order",
            text: "Date commande",
            sort: true,
            search: {},
        },
        {
            dataField: "num_bon",
            text: "N° BC",
            sort: true,
            search: {},
        },
        {
            dataField: "gc_client.nom_client",
            text: "Client",
            sort: true,
            search: {},
            render: (row: any, rowIndex: any) => {
                return (
                    <>{row?.gc_client_nom_client}</>
                )
            }
        },
        {
            dataField: "gc_client.address_client",
            text: "Adresse",
            sort: true,
            search: {},
            render: (row: any, rowIndex: any) => {
                return (
                    <>{row?.gc_client_address_client}</>
                )
            }
        },
    ];

    const filename = ""+currentTime();
    const exportToExcel = () => {
        (async () => {
            await ExportToExcelTable("commande_non_planifiee_"+filename, new DashBoardModel(), "/section-orders-not-planning-in-lab-site", columns, {per_page:"all"});
        })()
    }

    return (
        <>
            <div className={`card ${className}`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder fs-2 mb-1'>
                            Liste des commandes non planifiées
                        </span>
                    </h3>
                    <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover'>
                        {
                            <a href={void(0)} onClick={() => exportToExcel()} className="btn btn-sm btn-light-success ms-2">
                                <i className="fas fa-file-excel"></i> Exporter
                            </a>
                        }
                    </div>
                </div>
                {/* end::Header */}

                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        <DataTable
                            tableReload={tableReload}
                            dataModel={new DashBoardModel()}
                            path={"/section-orders-not-planning-in-lab-site"}
                            columns={columns}
                        />
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
        </>
    )
}

export {UnplannedOrdersView}
