/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useLayout} from '../core'

const Footer: FC = () => {
  const {classes} = useLayout()
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}>
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1'>
          <span className='text-dark fw-bold me-2'>
            {new Date().getFullYear()} &copy;
          </span>
          <a href={void(0)} className='menu-link text-gray-800 text-hover-primary text-uppercase me-2'>
            Smart Sentino 2.0
          </a>
          <span className="me-2">
            Powered by
          </span>
          <a href='https://www.honametech.com/' target='_blank' rel="noopener noreferrer" className='text-gray-800 text-hover-primary text-uppercase me-2'>
            Honamé Tech,
          </a>
          <span className="me-2">
            Tous droits réservés
          </span>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        <ul className='menu menu-gray-700 menu-hover-primary fw-bold order-1'>
          <li className='menu-item'>
            <Link to={'/general-condition'} className='menu-link pe-0'>
              Condition d'utilisation
            </Link>
          </li>
          <li className='menu-item'>
            <Link to={'/data-protection'} className='menu-link pe-0'>
              Protection des données
            </Link>
          </li>
        </ul>
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
