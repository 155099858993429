import React from "react";
import store, {RootState} from '../setup/redux/Store'
import {shallowEqual, useSelector} from 'react-redux'
import {actions as appActions} from '../setup/redux/AppRedux'
import AccountModel from '../models/AccountModel'
import {FormattedNumber, IntlProvider} from 'react-intl';
import {randomInt} from "crypto";

export const user = store.getState().authReducer.user
export const accessToken = store.getState().authReducer.accessToken
export const currentApp = store.getState().authReducer.currentApp

// @ts-ignore
export let responseApi = {
    type: null,
    message: null,
    result: null,
}

export interface InterfaceArrayObject {
    // @ts-ignore
    [key: any]: any
}

interface propsDownloadOrDeleteFileButton {
    fileUrl: string;
    onResetInputFile: () => void;
}

export const uniqueKey = (length = 8) => {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

// @ts-ignore
export const DownloadOrDeleteFileButton = ({fileUrl, onResetInputFile}) => {
    if (fileUrl)
        return (
            <>
                <div style={{fontSize: '11px'}}>
                    <a className='link-primary me-1' target='_blank' href={fileUrl} download>Télécharger</a>
                    |
                    <a href="#" className='link-danger ms-1' onClick={() => {
                        onResetInputFile()
                    }}>Supprimer</a>
                </div>
            </>
        )
    return (<></>)
}

export const buildFormData = (formData: any, data: any, parentKey?: any) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
        })
    } else {
        const value = data == null ? '' : data

        formData.append(parentKey, value)
    }
}

export const LoadingView = () => {
    return (
        <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}

export const saveFile = async (blob: any, fileName: string) => {
    const a = document.createElement('a')
    a.download = fileName
    a.href = URL.createObjectURL(blob)
    a.addEventListener('click', (e) => {
        setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000)
    })
    a.click()
}

export const CheckAccess = (method: string, submodule?: string) => {
    const appReducer = useSelector((state: RootState) => state.appReducer, shallowEqual)
    const userAccess = appReducer.currentUserAccess
    const setting = appReducer.setting
    if (!userAccess.length)
        return false
    if (!submodule) {
        return (userAccess.indexOf(method) > -1)
    } else {
        return (userAccess.indexOf(method) > -1) && (setting[submodule] > 0)
    }
}

export const isArray = (obj:any) => {
    return !!obj && obj.constructor === Array;
}

export const removeItemFromArray = (array: [], item: any) => {
    // @ts-ignore
    var index = array.indexOf(item)
    if (index !== -1) {
        array.splice(index, 1)
    }
    return array
}

export const removeItemsFromArray = (array: [], items: []) => {
    items.length > 0 && items.forEach(item => {
        let index = array.indexOf(item)
        if (index !== -1) {
            array.splice(index, 1)
        }
    })
    return array
}

export const removeDuplicateItemsFromArray = (array: []) => {
    return Array.from(new Set(array))
}

export const findItemByKeyAndValueInArray = (array: [], key: any, value: any) => {
    return array.find((element) => {
        return element[key] === value
    })
}

export const itemsFromItemsByKey = (items: [], key: string) => {
    let itemsByKey: any = []
    items.length > 0 && items.forEach(item => {
        itemsByKey.push(item[key])
    })
    return itemsByKey
}

export const objectHasValue = (obj : object, value: string) => {
    // @ts-ignore
    const find = (val : any)  => typeof val === "string" && obj[val] === value;
    return Object.keys(obj).some(find);
}

export const itemsFromItemsByValue = (items: [], value: string) => {
   /* const containsKeyword = (val : any)  => typeof val === "string" && val.indexOf(value) !== -1;
    return   items.filter((entry : any) => Object.keys(entry).map(key => entry[key]).some(containsKeyword));*/
    return   items.filter((entry : any) => objectHasValue(entry,value));
}

export const findItemByKeyAndValueInArrayObject = (array: InterfaceArrayObject, key: any, value: any) => {
    // @ts-ignore
    return array.find((element: object) => {
        // @ts-ignore
        //console.log(element[key])
        // @ts-ignore
        return element[key] === value
    })
}

export const getLabel = (type: any, value: any) => {
    let find = findItemByKeyAndValueInArrayObject(type, 'value', value);
    return find?.label || "";
}

export const currentTime = () => {
    return new Date().getTime();
}

export const mysqlDate = () => {
    return new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' ');
}

export const CurrentUser = async (dispatch: any) => {
    let dataModel = new AccountModel()
    const {data} = await dataModel.getMyAccount()
    if (data.type === "success") {
        await dispatch(appActions.saveCurrentUser(data.result))
        return data.result
    }
}

export const MyAccount = async () => {
    let dataModel = new AccountModel()
    const {data} = await dataModel.getMyAccount()
    if (data.type === "success") {
        return data.result
    }
    return null
}

export const Currency = (props: any) => {
    const currentUser = useSelector((state: RootState) => state.authReducer.user, shallowEqual)
    const appCurrency = useSelector((state: RootState) => state.appReducer.currency, shallowEqual)
    let amount = parseFloat(props.amount)
    amount = isNaN(amount) ? 0 : amount
    const currency = props?.currencyCode ? props?.currencyCode : appCurrency?.code;
    const minimum = 2;
    const maximum = 2;
    return (
        <IntlProvider locale={currentUser?.lang?.locale || navigator.language}>
            <FormattedNumber
                value={amount}
                style="currency"
                currency={currency}
                minimumFractionDigits={minimum}
                maximumFractionDigits={maximum}
            />
        </IntlProvider>
    )
};

export const Number = (props: any) => {
    const currentUser = useSelector((state: RootState) => state.authReducer.user, shallowEqual)
    let value = parseFloat(props.value)
    value = isNaN(value) ? 0 : value
    const minimum = 2;
    const maximum = 2;
    //console.log(navigator.language)
    return (
        <IntlProvider locale={currentUser?.lang?.locale || navigator.language}>
            <FormattedNumber
                value={value}
                minimumFractionDigits={minimum}
                maximumFractionDigits={maximum}
            />
        </IntlProvider>
    )
};
