import Model from "../Model";
import * as _helpers from "../../_metronic/helpers";

class DashBoardModel extends Model {

    private prefix = "executions/dashboard"

    getWithParams(path: any, params: any) {
        path = this.prefix + path
        return this.withParams(path, params)
    }

    getSectionStatusInSuiviPrestation() {
        let path = '/section-status-in-suivi-prestation'
        return this.getWithParams(path, {})
    }

    getSectionStatusInSuiviPlanning() {
        let path = '/section-status-in-suivi-planning'
        return this.getWithParams(path, {})
    }

    getSectionOrdersNotPlanningInLabSite() {
        let path = '/section-orders-not-planning-in-lab-site'
        return this.getWithParams(path, {})
    }

    getSectionOrdersOverTime() {
        let path = '/section-orders-over-time'
        return this.getWithParams(path, {})
    }

}

export default DashBoardModel
