/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'

type Props = {
  className: string,
  count : any,
}

const CountAllCustomers: React.FC<Props> = ({className, count}) => {
  return (
    <>
      {/* begin::Col */}
      <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7 ms-2 me-2'>
        <div className='d-flex align-items-center flex-column text-center'>
          <KTSVG path='/media/icons/duotone/Communication/Group.svg' className='svg-icon-2x svg-icon-primary d-block my-2 me-2'/>

          <div className='fw-bold fw-bolder fs-5 text-primary mt-3'>
            
          </div>

          <div className='text-primary fw-bold fw-bolder fs-1 mt-3'>
           {}
          </div>
        </div>
      </div>
      {/* end::Col */}
    </>
  )
}

export {CountAllCustomers}
