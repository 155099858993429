import Model from "../Model";
import * as _helpers from "../../_metronic/helpers";

class SuiviPrestationModel extends Model {

    private prefix = "executions/suivis-prestations"

    getWithParams(path: any, params: any) {
        path = this.prefix + path
        return this.withParams(path, params)
    }

}

export default SuiviPrestationModel
