import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup/redux/Store'

import {MenuItem} from './MenuItem'

const appType = {
  title: '',
  to: ''
}

export function MenuInner() {
  const currentApp = useSelector((state: RootState) => state.appReducer.currentApp, shallowEqual)

  if(currentApp == "GP") {
    appType.title = "Gestion parc instruments"
    appType.to = "instrument/dashboard"
  } else if(currentApp == "SE") {
    appType.title = "Gestion suivi des éxécutions"
    appType.to = "execution/dashboard"
  } else if(currentApp == "GC") {
    appType.title = "Gestion commerciale"
    appType.to = "business/dashboard"
  } else if(currentApp == "RE") {
    appType.title = "Reporting"
    appType.to = "reporting/dashboard"
  } 
  
  return (
    <>
      {/* <MenuItem currentApp={currentApp} title={appType.title} to={'/'+appType.to} /> */}

      <MenuItem currentApp="GP" title="Gestion parc instruments" to='/instrument/dashboard' />
      <MenuItem currentApp="SE" title="Gestion suivi des éxécutions" to='/execution/dashboard' />
      <MenuItem currentApp="GC" title="Gestion commerciale" to='/business/dashboard' />
    </>
  )
}
