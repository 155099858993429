/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {priorities_type} from "../../../../Types";

export function ToDoFilters({
    filter = (e: any) => {}
}) {

    const onChangeField = (e: any) => {
        filter(e)
    }

    return (
        <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Options de filtre</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5'>
                <div className='mb-2'>
                    <label className='form-label fw-bold'>N°BC : </label>
                    <div>
                        <input
                            placeholder="N°BC"
                            data-operator='like'
                            name='search[gc_commande.num_bon]'
                            onChange={onChangeField}
                            className="form-control form-control-lg form-control-solid"/>
                    </div>
                </div>
                <div className='mb-2'>
                    <label className='form-label fw-bold'>Client : </label>
                    <div>
                        <input
                            placeholder="Client"
                            data-operator='like'
                            name='search[gc_client.nom_client]'
                            onChange={onChangeField}
                            className="form-control form-control-lg form-control-solid"/>
                    </div>
                </div>
                <div className='mb-2'>
                    <label className='form-label fw-bold'>Status : </label>
                    <div>
                        <select
                            name="search[priority_planning]"
                            className='form-select form-select-solid'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            data-operator='='
                            onChange={onChangeField}
                        >
                            <option value=''>-- Veuillez selectionner --</option>
                            {
                                priorities_type && priorities_type.map((option, optionIndex) => {
                                    return (
                                        <option key={"option_priorities" + optionIndex}
                                                value={option['value']}>
                                            {option['label']}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className='mb-2'>
                    <label className='form-label fw-bold'>Observation : </label>
                    <div>
                        <input
                            placeholder="Observation"
                            data-operator='like'
                            name='search[operateur_planning]'
                            onChange={onChangeField}
                            className="form-control form-control-lg form-control-solid"/>
                    </div>
                </div>
            </div>

        </div>
    )
}
