/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup/redux/Store";
import {PageTitle} from '../../../_metronic/layout/core'

import {CheckAccess} from '../../Utilities';
import {SectionCustomersCountView} from "./components/status/SectionCustomersCountView";
import {LastOrdersActivitiesOnThisDay} from "./components/activities/LastOrdersActivitiesOnThisDay";
import {LastInvoicesActivitiesOnThisDay} from "./components/activities/LastInvoicesActivitiesOnThisDay";
import {GraphAmountQuotesByMonthInCurrentYear} from "./components/graphs/GraphAmountQuotesByMonthInCurrentYear";
import {GraphAmountOrdersByMonthInCurrentYear} from "./components/graphs/GraphAmountOrdersByMonthInCurrentYear";
import {GraphTurnoverByMonthInCurrentYear} from "./components/graphs/GraphTurnoverByMonthInCurrentYear";
import {GraphRecoveriesByMonthInCurrentYear} from "./components/graphs/GraphRecoveriesByMonthInCurrentYear";
import {SalesStatisticsOnThisMonth} from "./components/lists/SalesStatisticsOnThisMonth";

type Props = {
  cdi : any
}

const DashboardView: FC<Props> = () => (
  <>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-4'>
        <SectionCustomersCountView 
          className='card-xl-stretch mb-5 mb-xl-8'
          bgHeaderColor={'danger'}
        />
      </div>

      <div className='col-xxl-4'>
        <LastOrdersActivitiesOnThisDay 
          className='card-xxl-stretch mb-5 mb-xl-8'
        />
      </div>

      <div className='col-xxl-4'>
        <LastInvoicesActivitiesOnThisDay 
          className='card-xxl-stretch mb-5 mb-xl-8'
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-6'>
        <GraphAmountQuotesByMonthInCurrentYear 
          className='card-xxl-stretch mb-5 mb-xl-8'
          chartColor='orange'
          chartHeight='200px'
        />
      </div>

      <div className='col-xxl-6'>
        <GraphAmountOrdersByMonthInCurrentYear 
          className='card-xxl-stretch mb-5 mb-xl-8'
          chartColor='success'
          chartHeight='200px'
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-6'>
        <GraphTurnoverByMonthInCurrentYear
          className='card-xxl-stretch mb-5 mb-xl-8'
          chartColor='info'
          chartHeight='200px'
        />
      </div>

      <div className='col-xxl-6'>
        <GraphRecoveriesByMonthInCurrentYear
          className='card-xxl-stretch mb-5 mb-xl-8'
          chartColor='warning'
          chartHeight='200px'
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-12'>
        <SalesStatisticsOnThisMonth 
          className='card-xxl-stretch mb-5 mb-xl-8'
        />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const DashboardBusiness: FC = () => {
  const cdi = useSelector((state:RootState) => state.appReducer.cdi, shallowEqual)
  
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        Tableau de bord
      </PageTitle>

      <DashboardView cdi={cdi} />
    </>
  )
}

export {DashboardBusiness}
